<template>
    <div class="-mt-2 md:mt-0 mb-9">
        <div class="md:h-16  text-grays-400 w-full h-14 overflow-x-scroll lg:px-32 bxHooper my-5">
            <hooper
                :centerMode="false" 
                :wheelControl="false"
                :mouseDrag="false"
                :autoPlay="false"
                :itemsToShow="3"
                :infiniteScroll="false"
            >
                <slide  
                    v-for="(item, i) in csrYear" :key="i" 
                    class="font-pop text-sm  font-semibold lg:text-lg text-black"
                >
                    <span
                        @click="handleTabYear(item,i)"
                        :class="{'activeTabYear' : i == tabyearActive }"
                        class=" w-24 border-b-4 border-solid border-white cursor-pointer pb-2  duration-100 hover:border-primary hover:text-primary "
                    > 
                        {{item}}
                    </span>
                   
                </slide>
                
                <hooper-navigation slot="hooper-addons"></hooper-navigation>
           
            </hooper>
        </div>
        <div class="bg-grays-100 md:h-16 text-grays-400 w-full h-14 overflow-x-scroll">
            <ul class="flex justify-between items-center h-full lg:px-32 m-auto">
                <li 
                    v-for="(item, index) in tabMenu" 
                    :key="index"
                    class="px-2 mx-3 capitalize font-pop text-xs lg:text-sm  text-grays-400 font-semibold w-80 h-full flex items-center cursor-pointer duration-100 justify-center border-b-4 border-solid border-grays-200 hover:border-primary hover:text-primary flex-wrap"
                    :class="{'activeTab' : index == tabActive }"
                    @click="handleTab(index)"
                >
                   
                    <span v-if="lang == 'id'">
                        {{item.name}}
                    </span>
                    <span v-else>
                        {{item.name_eng}}
                    </span>
                </li>
            </ul>
        </div>

        <section class="my-4 p-3 lg:px-32 min-h-10 mb-40 max-w-screen-xl m-auto" >
            <div 
                class="w-full lg:mx-3 text-left"
                v-if="tabActive == 0"
            >
                <!-- <div class="text-sm mb-1 md:text-base">{{ $t('csr.environment.title1') }}</div>
                <h5 class="font-semibold text-base md:text-xl mb-4">{{ $t('csr.environment.title2') }}</h5> -->
                
                <progressive-img
                    :src="tbSelect.csr ? tbSelect.csr[0].image : imgPreload"
                    :placeholder="imgPreload"
                    :blur=30
                    style="max-width:100%"
                />
                <div class="text-xs" v-if="tbSelect.csr ? tbSelect.csr[0] : ''">
                    <span v-if="lang =='id'">{{tbSelect.csr[0].caption}}</span>
                    <span v-else>{{tbSelect.csr[0].caption_eng}}</span>
                </div>
                <div 
                    v-if="lang =='id'"
                    class="mt-10 text-sm lg:text-base text-justify dvEnvi" 
                    v-html="tbSelect.csr[0].content"
                >
                </div>
                <div 
                    v-else
                    class="mt-10 text-sm lg:text-base text-justify dvEnvi" 
                    v-html="tbSelect.csr[0].content_eng"
                >
                </div>

                <!-- <div class="mt-20 w-40 cursor-pointer text-grays-50 h-8 bg-green-500 rounded-sm flex items-center justify-center text-sm">
                    see more csr news
                </div> -->

                 
            </div>
            <div class="w-full lg:mx-3 text-left"  v-if="tabActive == 1">
                <!-- <div class="text-sm mb-1 md:text-base">{{ $t('csr.safety.title1') }}</div>
                <h5 class="font-semibold text-base md:text-xl mb-4">{{ $t('csr.safety.title2') }}</h5> -->
                <!-- <carousel
                    :nav = true
                    :centerMode = true
                    :autoPlay = true
                    :playSpeed = 8000
                    :dtImg = hdtImg
                /> -->
                <progressive-img
                    :src="tbSelect.csr ? tbSelect.csr[1].image : imgPreload"
                    :placeholder="imgPreload"
                    :blur=30
                    style="max-width:100%"
                />
                <div class="text-xs" v-if="tbSelect.csr ? tbSelect.csr[1] : ''">
                    <span v-if="lang =='id'">{{tbSelect.csr[1].caption}}</span>
                    <span v-else>{{tbSelect.csr[1].caption_eng}}</span>
                </div>
                <div 
                    v-if="lang =='id'"
                    class="mt-10 text-sm lg:text-base text-justify dvSafety" v-html="tbSelect.csr[1].content"
                > 

                </div> 

                <div 
                    v-else
                    class="mt-10 text-sm lg:text-base text-justify dvSafety" v-html="tbSelect.csr[1].content_eng"
                >   
                </div>
                <!-- <div class="mt-20 w-40 cursor-pointer text-grays-50 h-8 bg-green-500 rounded-sm flex items-center justify-center text-sm">
                    see more csr news
                </div> -->
            </div>
            <div class="w-full lg:mx-3 text-left" v-if="tabActive == 2">
                <!-- <div class="text-sm mb-1 md:text-base">{{ $t('csr.society.title1') }}</div>
                <h5 class="font-semibold text-base md:text-xl mb-4">{{ $t('csr.society.title2') }}</h5> -->

                <progressive-img
                    :src="tbSelect.csr ? tbSelect.csr[2].image : imgPreload"
                    :placeholder="imgPreload"
                    :blur=30
                    style="max-width:100%"
                    v-if="!data.csr_video"
                />
                <div class="h h-30 w-full relative" v-else>
                    
                    <!-- <video id="videoElement" class="w-full h-30" controls :poster="imgSociety[0] ? imgSociety[0].image : imgPreload" 
                    @canplay="updatePaused" @playing="updatePaused" @pause="updatePaused">
                        <source src="https://youtu.be/3fZgLIN6FmQ" type="video/mp4"> 
                        <p>Sorry, there's a problem playing this video. Please try using a different browser.</p>
                    </video> -->

                    <iframe :src="data.csr_video" title="YouTube video player" frameborder="0" class="w-full h-96" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                    <div class="controls">
                        <img src="@/assets/img/play-button.svg"  v-show="paused" @click="play" alt="pause" class="w-20 h-20  md:w-30 md:h-30 cursor-pointer absolute tptl">
                        <img src="@/assets/img/youtube-icon.svg" v-show="playing" @click="pause" alt="play" class="w-0 h-0 cursor-pointer absolute tptl">
                    </div>
                    
                </div>

                <div class="text-xs" v-if="tbSelect.csr ? tbSelect.csr[2] : ''">
                    <span v-if="lang =='id'">{{tbSelect.csr[2].caption}}</span>
                    <span v-else>{{tbSelect.csr[2].caption_eng}}</span>
                </div>
                <div 
                    v-if="lang =='id'"
                    class="mt-10 text-sm lg:text-base text-justify dvSoce" v-html="tbSelect.csr[2].content"
                >
                </div>
                <div 
                    v-else
                    class="mt-10 text-sm lg:text-base text-justify dvSoce" v-html="tbSelect.csr[2].content_eng"
                >
                </div>
                <!-- <div class="mt-20 w-40 cursor-pointer text-grays-50 h-8 bg-green-500 rounded-sm flex items-center justify-center text-sm">
                    see more csr news
                </div> -->
            </div>
            <div class="w-full lg:mx-3 text-left" v-if="tabActive == 3">
                <!-- <h5 class="font-semibold text-base md:text-xl mb-4">{{ $t('csr.sunsb.title1') }}</h5> -->
                <progressive-img
                    :src="tbSelect.csr ? tbSelect.csr[3].image : imgPreload"
                    :placeholder="imgPreload"
                    :blur=30
                    style="max-width:100%"
                />
                <div class="text-xs" v-if="tbSelect.csr ? tbSelect.csr[3] : ''">
                    <span v-if="lang =='id'">{{tbSelect.csr[3].caption}}</span>
                    <span v-else>{{tbSelect.csr[3].caption_eng}}</span>
                </div>

                <div 
                    v-if="lang =='id'"
                    class="mt-10 text-sm lg:text-base text-justify dvEnvi" 
                    v-html="tbSelect.csr[3].content"
                >
                </div>
                <div 
                    v-else
                    class="mt-10 text-sm lg:text-base text-justify dvEnvi" 
                    v-html="tbSelect.csr[3].content_eng"
                >
                </div>
                
            </div>
            
        </section>
    </div>
</template>
<script>
import {
    Hooper,
    Slide,
    Navigation as HooperNavigation
} from 'hooper';
import 'hooper/dist/hooper.css';
import { mapState } from 'vuex';
export default {
    components:{
        carousel : () => import ("@/components/carousel"),
        Hooper,
        Slide,
        HooperNavigation
    },
    props:[
        'csrLang',
        'lang',
        'csrYear',
        'csr'
    ],
    
    data() {
        return {
            tabMenu:[
                {
                    name : 'Lingkungan Hidup',
                    name_eng : 'Environment'

                },
                {
                    name : 'Ketenagakerjaan, Kesehatan dan Keselamatan Kerja',
                    name_eng : 'Employment & K3 Practices'
                },
                {
                    name : 'Pengembangan Sosial Dan Kemasyarakatan',
                    name_eng : 'Social Community'
                },
                {
                    name : 'Tanggung Jawab Terhadap Pelanggan',
                    name_eng : 'Responsibilities to Customer'
                }
            ],
            tabActive: 0,
            videoElement: null,
            paused: null,
            imgPreload:require(`@/assets/img/404.jpg`),
            tabyearActive:0,
            activeYear: '',
            tbSelect: ''
        }
    },
    mounted() {
        this.activeYear = this.csrYear[0]

        const result = this.csr.filter((item) => {
			return item.year == this.activeYear
		})

        this.tbSelect = result[0]

        console.log(this.tbSelect.csr[0].image)
    },
    computed: {
        ...mapState({
            data: state => state.home.home ? state.home.home.data :'',
        }),
        playing() { 
            return !this.paused; 
        },
        imgEnv(){
            return this.$store.getters['image/imgWtFilter']('environment')
        },
        imgSafety(){
            return this.$store.getters['image/imgWtFilter']('safety')
        },
        imgSociety(){
            return this.$store.getters['image/imgWtFilter']('society')
        },
        imgSuns(){
            return this.$store.getters['image/imgWtFilter']('sustainability')
        }
        
    },

    methods: {
        handleTab(i){
            this.tabActive = i
        },
        handleTabYear(item,i){
            this.tabyearActive = i
            this.activeYear = item

            const result = this.csr.filter((item) => {
				return item.year == this.activeYear
			})

            this.tbSelect = result[0]

        },
        updatePaused(event) {
            this.videoElement = event.target;
            this.paused = event.target.paused;
        },
        play() {
            this.videoElement.play();
        },
        pause() {
            this.videoElement.pause();
        }
    },

    
}
</script>

<style lang="scss">
    .activeTab{
        @apply bg-primary text-grays-50 border-primary hover:text-grays-50;
    }

    .activeTabYear{
        @apply  text-primary border-primary ;
    }
    
    .tptl{
        top: 47%;
        left: 45%;
    }

    .dvEnvi, .dvSafety, .dvSoce{
        p{
            @apply mb-6;
        }

        ul, ol{
            @apply text-sm lg:text-base mb-6 pl-5;

            li{
                @apply mb-4;
            }
        }

        ol{
            @apply list-decimal;
        }

        ul{
             @apply list-disc;
        }
    }

    .bxHooper{
        .hooper-slide{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 5px;
            margin-right: 5px;
            
        }

        .hooper-prev{
            left: 0rem;
        }
        .hooper-next{
            right: 0rem;
        }

        
    }

    
</style>